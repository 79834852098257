import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import theme from "../../src/config/theme";
import {
  googleFontsCdn,
  metaDescription,
  metaKeywords,
  appTitle,
} from "../../src/config/theme/theme.config";

interface TopLayoutProps {
  children: React.ReactNode;
}

const TopLayout: React.FC<TopLayoutProps> = ({ children }) => {
  return (
    <React.Fragment>
      <Helmet>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
        <title>{`${appTitle}`}</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href={googleFontsCdn} rel="stylesheet" />
        <link rel="stylesheet" href="https://use.typekit.net/wme2bdf.css" />
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {children}
      </ThemeProvider>
    </React.Fragment>
  );
};

export default TopLayout;
