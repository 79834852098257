import { lighten, darken } from "polished";

const primaryColor = "#03002D";
const secondaryColor = "#49C3D4";
const alternateColor = "#472D68";
const accentColor = "#AB28A4";

const appTitle = "Ornelas.tech | Web3 and Blockchain Software solutions";
const metaDescription =
  "Ornelas.tech is a Web3 consulting company  that offers a range of solutions to enhance your web3 user experience, including end-to-end design and development, thorough audit and optimization, seamless integration with existing systems, usability testing, and comprehensive training and support.";
const metaKeywords =
  "web3, blockchain, digital products, software, website, web development, custom software, Austin, San Antonio";

const googleFontsCdn =
  "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap";

const fontFamilies = "'Source Sans Pro', sans-serif, 'Open Sans', sans-serif";
const headlineFont = "'Source Sans Pro', sans-serif";
const bodyFont = "'Open Sans', sans-serif";

const headlineFontStyles = {
  fontFamily: headlineFont,
  fontWeight: [400, 700],
  fontStyle: "normal",
  // letterSpacing: "-0.02em",
};

const commonColorStyles = {
  white: "#fff",
  black: "#000",
  grey: "#696a6c",
  lightGrey: "#fafafa",
};

const customColorStyles = {
  alternate: {
    light: lighten(0.1, alternateColor),
    main: alternateColor,
    dark: darken(0.1, alternateColor),
  },
  accent: {
    light: lighten(0.1, accentColor),
    main: accentColor,
    dark: darken(0.1, accentColor),
  },
  gradient: {
    main: `linear-gradient(180deg, rgba(28,27,82,1) 0%, rgba(21,19,75,1) 40%, rgba(3,0,45,0) 100%)`,
  },
};

export {
  googleFontsCdn,
  primaryColor,
  secondaryColor,
  alternateColor,
  accentColor,
  fontFamilies,
  headlineFont,
  headlineFontStyles,
  commonColorStyles,
  bodyFont,
  metaDescription,
  metaKeywords,
  appTitle,
  customColorStyles,
};
