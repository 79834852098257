exports.components = {
  "component---src-pages-case-studies-iceman-tsx": () => import("./../../../src/pages/case-studies/iceman.tsx" /* webpackChunkName: "component---src-pages-case-studies-iceman-tsx" */),
  "component---src-pages-consultation-tsx": () => import("./../../../src/pages/consultation.tsx" /* webpackChunkName: "component---src-pages-consultation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-create-testimonial-tsx": () => import("./../../../src/pages/create-testimonial.tsx" /* webpackChunkName: "component---src-pages-create-testimonial-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-projects-vionex-tsx": () => import("./../../../src/pages/projects/vionex.tsx" /* webpackChunkName: "component---src-pages-projects-vionex-tsx" */),
  "component---src-pages-promo-tsx": () => import("./../../../src/pages/promo.tsx" /* webpackChunkName: "component---src-pages-promo-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

